.replacement-cars-week[data-v-a124f0cf] {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  height: auto;
}
.sentinel[data-v-a124f0cf] {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 10000px;
}
.sentinel.-bottom[data-v-a124f0cf] {
  align-items: flex-start;
}
.loading-icon[data-v-a124f0cf] {
  font-size: 2rem;
  color: var(--color-text-primary);
}