.replacement-car-row[data-v-eaa9f1c3] {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.4rem;
  align-items: flex-start;
}
.replacement-car[data-v-eaa9f1c3] {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  border: 0.4px solid var(--color-border-primary);
  min-width: var(--cars-width);
  max-width: var(--cars-width);
  padding: 0.6rem 0.4rem;
  gap: 0.4rem;
  border-radius: var(--radius);
  display: flex;
  justify-content: space-between;
  height: var(--section-height);
  margin-bottom: 0.4rem;
}
.week-program[data-v-eaa9f1c3] {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.4rem;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border-primary);
  border-bottom: none;
  border-right: none;
}
.replacement-car-row:first-child .week-program[data-v-eaa9f1c3] {
  border-radius: var(--radius) 0;
}
.slot[data-v-eaa9f1c3] {
  border-right: 1px solid var(--color-border-primary);
}
.slot[data-v-eaa9f1c3]:last-child {
  border-right: none;
}