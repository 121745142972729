.replacement-cars-week-calendar[data-v-c5fee49e] {
  display: flex;
  flex-direction: column;
  width: calc(100% + 0.4rem);
  position: sticky;
  top: -1.1rem;
  z-index: 1;
  background-color: var(--content-bg--color-light);
}
.replacement-cars-week-header[data-v-c5fee49e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.week-name[data-v-c5fee49e] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
}
.replacement-cars-week-days[data-v-c5fee49e] {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  padding-left: var(--cars-width);
  padding-top: 1rem;
}
.replacement-cars-week-day[data-v-c5fee49e] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 3rem;
}
.week-table[data-v-c5fee49e] {
  display: flex;
  flex-direction: column;
}