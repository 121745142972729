.replacement-cars-list-empty[data-v-aa6ed03b] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.replacement-cars-list-empty-card[data-v-aa6ed03b] {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: 0.4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  background-color: var(--content-bg--color-lighter);
  padding: 1rem;
  min-width: 35rem;
  min-height: 15rem;
  overflow: hidden;
}
.replacement-cars-list-empty-icon[data-v-aa6ed03b] {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-20%, -30%);
  color: var(--color-icon);
  opacity: 0.1;
  z-index: 1;
}
.replacement-cars-list-empty-text[data-v-aa6ed03b] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
}
.replacement-cars-list-empty-button[data-v-aa6ed03b] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: 0.4rem 0.8rem;
  border-radius: var(--radius);
  cursor: pointer;
  z-index: 2;
  opacity: 1;
}
.replacement-cars-list-empty-button[data-v-aa6ed03b]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
  color: var(--color-text-quaternary);
  opacity: 1;
}