.replacement-speed-dial-button[data-v-adbf0806] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-text-black);
  opacity: 0.8;
}
.replacement-speed-dial-button[data-v-adbf0806]:hover {
  background-color: var(--color-button-bg-focus);
  opacity: 1;
}
.replacement-speed-dial-item-button[data-v-adbf0806] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-text-secondary);
  background-color: var(--content-bg--floating-button);
}
.replacement-speed-dial-item-button[data-v-adbf0806]:hover {
  color: var(--color-text-primary);
}
.replacement-speed-dial-item-button.-danger[data-v-adbf0806]:hover {
  color: var(--color-text-warning);
}
.fade-enter-active[data-v-adbf0806],
.fade-leave-active[data-v-adbf0806] {
  transition: opacity var(--p-transition-duration) ease;
}
.fade-enter-from[data-v-adbf0806],
.fade-leave-to[data-v-adbf0806] {
  opacity: 0;
}