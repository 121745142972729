.insurance-group-tooltip[data-v-df5fde51] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.group-name[data-v-df5fde51] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 1rem;
}
.group-insurances[data-v-df5fde51] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 20rem;
  gap: 0.4rem;
}
.insurance[data-v-df5fde51] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  padding: 0.1rem 0.4rem;
  border: 0.4px solid var(--color-border-secondary);
  border-radius: var(--radius-max);
}
.insurance.-highlighted[data-v-df5fde51] {
  background-color: var(--color-button-bg-submit);
  border-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
}