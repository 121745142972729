.replacement-slot[data-v-2bb7fd58] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  line-height: 1rem;
  color: var(--color-text-black);
  position: absolute;
  height: calc(100% - 0.4rem);
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--color-border-primary);
  overflow: hidden;
}
.replacement-slot.-finished[data-v-2bb7fd58] {
  background-color: var(--car-state-finished-shaded);
}
.replacement-slot.-in-progress[data-v-2bb7fd58] {
  background-color: var(--car-state-coming-shaded);
}
.replacement-slot.-coming[data-v-2bb7fd58] {
  background-color: var(--content-bg--color-light);
  color: var(--color-text-primary);
  border: 0.4px solid var(--car-state-todo-border);
}
.replacement-details[data-v-2bb7fd58] {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 98%;
}
.replacement-names[data-v-2bb7fd58] {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.danger-icon[data-v-2bb7fd58] {
  color: var(--color-text-warning);
}
.replacement-dates[data-v-2bb7fd58] {
  color: var(--color-text-black-secondary);
}
.replacement-dates.-coming[data-v-2bb7fd58] {
  color: var(--color-text-secondary);
}