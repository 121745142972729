.maintenance-time-slot[data-v-fa295d4a] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  line-height: 1rem;
  color: var(--color-text-warning);
  border: 1px solid var(--color-bg-warning);
  position: absolute;
  height: calc(100% - 0.4rem);
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--content-bg--color-light);
}
.maintenance-time-dates[data-v-fa295d4a] {
  opacity: 0.9;
}