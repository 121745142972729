.replacement-cars-list-toolbar[data-v-1d00f511] {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
}
.replacement-cars-list-toolbar-left[data-v-1d00f511],
.replacement-cars-list-toolbar-right[data-v-1d00f511] {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  width: calc(50% - 5rem);
}
.replacement-cars-list-toolbar-right[data-v-1d00f511] {
  justify-content: space-between;
  align-items: center;
  width: calc(50% + 5rem);
}
.replacement-cars-list-toolbar-left[data-v-1d00f511],
.replacement-cars-list-toolbar-right[data-v-1d00f511] {
  display: flex;
  gap: 1rem;
}
.replacement-cars-list-toolbar-right[data-v-1d00f511] {
  justify-content: space-between;
}
.replacement-cars-list-button[data-v-1d00f511] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  padding: 0.5rem;
}